var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "monitorVehicleDetail padding20" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "120px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "车牌号码：", prop: "plateNumber" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.disabled },
                model: {
                  value: _vm.form.plateNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "plateNumber", $$v)
                  },
                  expression: "form.plateNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车牌颜色：", prop: "plateColor" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择车牌颜色",
                  },
                  model: {
                    value: _vm.form.plateColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "plateColor", $$v)
                    },
                    expression: "form.plateColor",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "蓝色", value: "0" } }),
                  _c("el-option", { attrs: { label: "绿色", value: "2" } }),
                  _c("el-option", { attrs: { label: "黄色", value: "1" } }),
                  _c("el-option", { attrs: { label: "白色", value: "5" } }),
                  _c("el-option", { attrs: { label: "黑色", value: "4" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "50",
                  autosize: "",
                  placeholder: "请输入备注信息",
                  disabled: _vm.disabled,
                },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.disabled,
                      expression: "!disabled",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.onSubmit },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.disabled,
                      expression: "!disabled",
                    },
                  ],
                  staticStyle: { "margin-left": "30px" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.disabled,
                      expression: "disabled",
                    },
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }